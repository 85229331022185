import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parallax-section',
  templateUrl: './parallax-section.component.html',
  styleUrls: ['./parallax-section.component.scss']
})
export class ParallaxSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  toTheAppStore($e) {
    window.open("https://itunes.apple.com/us/app/scrum-planning-poker/id910656967?ls=1&mt=8");
  }

}
