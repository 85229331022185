import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SupportComponent } from './pages/support/support.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ParallaxSectionComponent } from './components/parallax-section/parallax-section.component';
import { PresentationSectionComponent } from './components/presentation-section/presentation-section.component';
import { FeaturesSectionComponent } from './components/features-section/features-section.component';
import { DownloadSectionComponent } from './components/download-section/download-section.component';
import { ContactComponent } from './components/contact/contact.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    SupportComponent,
    PrivacyComponent,
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    ParallaxSectionComponent,
    PresentationSectionComponent,
    FeaturesSectionComponent,
    DownloadSectionComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
