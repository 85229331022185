import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presentation-section',
  templateUrl: './presentation-section.component.html',
  styleUrls: ['./presentation-section.component.scss']
})
export class PresentationSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
